<template>
  <div>
    <bw-loader v-if="loading"/>
    <section v-if="!loading" class="d-block mt-50">
      <div class="container">
        <h3 class="text-primary mb-40 font-30">Tenet 3: Striving for transparency and traceability</h3>
        <h4 class="text-primary mb-20">Delivery Information</h4>
        <div class="row h-100">
          <div ref="contractInfo" class="col-md-5 col-12">
            <div class="bg-white py-25 px-60 delivery-info">
              <div class="d-flex justify-content-between mb-30">
                <h6 class="font-weight-bold text-f3">Contract Number</h6>
                <span class="w-50 text-left">{{ contract.contract_no }}</span>
              </div>
              <div class="d-flex justify-content-between mb-30">
                <h6 class="font-weight-bold text-f3">Delivery month(s)</h6>
                <span class="w-50 text-left">{{ contract.delivery_month }}</span>
              </div>
              <div class="d-flex justify-content-between mb-30">
                <h6 class="font-weight-bold text-f3">NKG Exporter</h6>
                <span class="w-50 text-left">{{ contract.exporter_name }}</span>
              </div>
              <div v-if="contract.importer_name !== 'N/A'" class="d-flex justify-content-between mb-30">
                <h6 class="font-weight-bold text-f3">NKG Importer</h6>
                <span class="w-50 text-left">{{ contract.importer_name }}</span>
              </div>
              <div v-show="contract.customer_name !== 'N/A'" class="d-flex justify-content-between mb-30">
                <h6 class="font-weight-bold text-f3">NKG Customer</h6>
                <span class="w-50 text-left">{{ contract.customer_name }}</span>
              </div>
              <div class="d-flex justify-content-between mb-30">
                <h6 class="font-weight-bold text-f3">Country of Origin</h6>
                <span class="w-50 text-left">{{ contract.country_name }}</span>
              </div>
              <div class="d-flex justify-content-between mb-30">
                <h6 class="font-weight-bold text-f3">Net Weight (kgs)</h6>
                <span class="w-50 text-left">{{ contract.weight | formatNumberLocale }}</span>
              </div>
              <div class="d-flex justify-content-between mb-30">
                <h6 class="font-weight-bold text-f3">Quality</h6>
                <span class="w-50 text-left">{{ contract.contract_quality || 'N/A' }}</span>
              </div>
              <div class="d-flex justify-content-between mb-30">
                <h6 class="font-weight-bold text-f3">NKG Bloom Coffee</h6>
                <span v-show="contractBloomShare === 0" class="w-50 text-left">N/A</span>
                <span v-show="contractBloomShare !== 0" class="w-50 text-left">{{ contractBloomShare }}%</span>
              </div>
              <div class="d-flex justify-content-between mb-30">
                <h6 class="font-weight-bold text-f3">Region (s)</h6>
                <span class="w-50 text-left">{{ contractRegion }}</span>
              </div>
              <div class="d-flex justify-content-between mb-30">
                <h6 class="font-weight-bold text-f3">Farmer Group/Label</h6>
                <span class="w-50 text-left">{{ contractGroup }}</span>
              </div>
              <div class="d-flex justify-content-between">
                <h6 class="font-weight-bold text-f3">Farmer Subgroup</h6>
                <span class="w-50 text-left">{{ contractSubGroup }}</span>
              </div>
            </div>
          </div>
          <div class="col-md-7 col-12">
            <content-loader
              v-show="mapContentLoading"
              :height="250"
              :speed="1"
              :width="476"
              primaryColor="#ede9e9"
              secondaryColor="#ecebeb"
            >
              <rect height="250" rx="5" ry="5" width="500" x="0" y="0"/>
            </content-loader>
            <div v-if="geoPositions.length && !mapContentLoading" class="tracibility-2-map">
              <MapChart
                v-if="geoPositions.length"
                :enabled-map-legends="enabledMapLegends"
                :geo-positions="geoPositions"
                :map-center="mapCenter"
                :map-height="mapHeight"
              />
            </div>
            <div v-else class="text-center " style="width: 600px; height: 500px;">
              <div class="row align-items-center h-100">
                <div class="mx-auto">
                  <div class="h-100 justify-content-center">
                    <div>
                      No enough data
                      found to
                      display map
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-show="importerContractRefineTableRows.length <= 0">
          <h4 class="text-primary mt-60 mb-20">Details</h4>
          <table class="table table-striped text-center">
            <thead>
            <tr>
              <th>Bill of Landing</th>
              <th>Container (s)</th>
              <th>
                ICO Lot Number
              </th>
              <th>
                Port of Origin
              </th>
              <th>
                Port of Destination
              </th>
              <th>
                Export Mill
              </th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(rowData, _rowIndex) in billOfLadingTableRows" :key="_rowIndex">
              <td v-for="(item, _i) in rowData" :key="_i">
                <span v-html="item"></span>
              </td>
            </tr>
            <tr v-if="billOfLadingTableRows.length<=0">
              <td :colspan="6">No data available</td>
            </tr>
            </tbody>
          </table>
        </div>
        <div v-show="importerContractRefineTableRows.length > 0">
          <h4 class="text-primary mt-60 mb-20">Details</h4>
          <table class="table table-striped text-center">
            <thead>
            <tr>
              <th>Bill of Landing</th>
              <th>Container (s)</th>
              <th>ICO Lot Number</th>
              <th>Port of Origin</th>
              <th>Port of Destination</th>
              <th>Export Mill</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(rowData, _rowIndex) in importerContractRefineTableRows" :key="_rowIndex">
              <td v-for="(item, _i) in rowData" :key="_i">
                <span v-html="item"></span>
              </td>
            </tr>
            <tr v-if="importerContractRefineTableRows.length<=0">
              <td :colspan="6">No data available</td>
            </tr>
            </tbody>
          </table>
        </div>
        <div>
          <h4 class="text-primary mt-60 mb-20">Regions and farmer groups/labels</h4>
          <table class="table table-striped text-center">
            <thead>
            <tr>
              <th v-for="(item, _i) in regionGroupTableHeads" :key="_i">{{ item.title }} <br> {{ item.subtitle }}</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(rowData, _rowIndex) in regionAndFarmersGroupRows" :key="_rowIndex">
              <td v-for="(item, _i) in rowData" :key="_i">
                <span v-html="item"></span>
              </td>
            </tr>
            <tr v-if="regionAndFarmersGroupRows.length<=0">
              <td :colspan="9">No data available</td>
            </tr>
            </tbody>
          </table>
        </div>
        <div>
          <div v-if="isAdminUser">
            <h4 class="text-primary mt-60 mb-20">Further Resources on</h4>
            <div class="bg-primary-light en-dr-bg1 p-20">
              <div class="row no-gutters p-lg-0 p-20">
                <div class="col-lg-7 col-md-12 m-auto">
                  <div class="row no-gutters p-lg-0 p-20">
                    <div class="col-md-8 ml-auto">
                      <div class="d-flex justify-content-between align-items-center">
                        <p class="w-50 m-0"> More than one resource can be added by clicking on this button </p>
                        <button class="btn btn-add" type="submit" @click="addMoreFurtherResource"> + Add</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="bg-white py-40">
              <div class="row efsc-sec-10">
                <div class="col-lg-10 col-12 m-auto">
                  <div v-for="(_resource, _index) in furtherResources" :key="_index" class="form-group mb-55">
                    <div class="form-group mt-30">
                      <div class="form-row">
                        <div class="col-12 col-lg-4 col-sm-12">
                          <label class="col-form-label">Resource title</label>
                        </div>
                        <div class="col-12 col-md-12 col-lg-6 col-sm-12">
                          <input v-model="_resource.title" class="admin-input form-control" placeholder="Your title"
                                 type="text"/>
                          <div
                            v-if="$v.$error & !$v.furtherResources.$each.$iter[_index].title.required"
                            class="error">
                            Title is required.
                          </div>
                        </div>
                        <div class="col-lg-2">
                          <div class="d-inline-flex float-right">
                            <button
                              v-if="furtherResources.length > 0"
                              class="btn btn-add-success float-right"
                              @click="removeFromChildForm(furtherResources,_index, 'api', resourcesContentsState)">
                              <i aria-hidden="true" class="fa fa-times"></i> Close
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="form-group mb-30">
                      <div class="form-row">
                        <div class="col-12 col-md-12 col-lg-4 col-sm-12">
                          <label class="col-form-label">File type</label>
                        </div>
                        <div class="col-12 col-md-8 col-lg-8 col-sm-12">
                          <input v-model="_resource.subtitle" class="admin-input form-control"
                                 placeholder="File type"
                                 type="text">
                          <div
                            v-if="$v.$error & !$v.furtherResources.$each.$iter[_index].subtitle.required"
                            class="error">
                            Type is required.
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="form-group mb-30">
                      <div class="form-row">
                        <div class="col-12 col-md-12 col-lg-4 col-sm-12">
                          <label class="col-form-label">Date</label>
                        </div>
                        <div class="col-12 col-md-8 col-lg-8 col-sm-12">
                          <input v-model="_resource.date"
                                 class="admin-input form-control"
                                 name="name" placeholder="Date"
                                 type="date">
                          <div
                            v-if="$v.$error & !$v.furtherResources.$each.$iter[_index].date.required"
                            class="error">
                            Date is required.
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="form-group mt-30">
                      <div class="form-row">
                        <div class="col-12 col-md-2 col-lg-4 col-sm-12">
                          <label class="col-form-label">Resource file</label>
                        </div>
                        <div class="col-12 col-md-10 col-lg-8 col-sm-12 content-block">
                          <div class="wrapper">
                            <div class="drop drop-inline drop-sm mr-30">
                              <div class="cont">
                                <img alt="" src="@/assets/img/icons/upload1.svg">
                                <div v-if="!_resource.file.tsync_id" class="desc"> Upload File</div>
                                <div v-if="_resource.file.tsync_id" class="desc">{{ _resource.file.name }}</div>
                              </div>
                              <input type="file" @change="onFileChange($event,_index)">
                            </div>
                            <div class="d-inline-flex">
                              <i v-show="_resource.file.tsync_id" aria-hidden="true"
                                 class="fa fa-times ml-2 clear-file"
                                 @click="clearFile(furtherResources, _index)"> Clear</i>
                            </div>
                            <p class="mt-10">
                              Or <br>
                              <input v-model="_resource.more_link" class="mt-10 admin-input form-control"
                                     placeholder="Add URL"
                                     type="text">
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="from-group mb-30">
                      <div class="form-row">
                        <div class="col-12 col-md-2 col-lg-4 col-sm-12">
                          <label class="col-form-label">Resource icon</label>
                        </div>
                        <div class="col-12 col-md-10 col-lg-8 col-sm-12 content-block">
                          <div class="drop drop-inline mr-30 drop-sm">
                            <div class="cont">
                              <img
                                v-if="_resource.icon && _resource.icon.previewUrl"
                                :src="_resource.icon && _resource.icon.previewUrl" alt="Icon" class="icon-preview"/>
                              <img
                                v-else-if="_resource.icon && photoPreviewMapping[_resource.icon.tsync_id]"
                                :src="photoPreviewMapping[_resource.icon.tsync_id]" alt="Icon"
                                class="icon-preview"/>
                              <img
                                v-else-if="_resource.icon && !_resource.icon.previewUrl && !_resource.icon.relative_url"
                                alt="" src="@/assets/img/icons/upload1.svg">
                              <img
                                v-else :src="_resource.icon && _resource.icon.relative_url | makeAbsUrl" alt="Icon"
                                class="icon-preview"/>
                              <div v-if="_resource.icon && !_resource.icon.name" class="desc"> Upload Icon</div>
                              <div v-if="_resource.icon && _resource.icon.name" class="desc">
                                {{ _resource.icon.name | textTruncate(15) }}
                              </div>
                            </div>
                            <input
                              accept="image/*"
                              type="file"
                              @change="onIconChange($event,_index)"
                            >
                            <div
                              v-if="$v.$error & !$v.furtherResources.$each.$iter[_index].icon.tsync_id.required"
                              class="error">
                              Icon is required.
                            </div>
                          </div>
                          <div v-if="_resource.icon && _resource.icon.name" class="d-inline-flex">
                            <i aria-hidden="true" class="fa fa-times ml-2 clear-image"
                               @click="clearIcon(furtherResources,_index)"> Clear</i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group mb-30">
                    <div class="row">
                      <div class="col-12 col-md-12 col-lg-10 col-sm-12">
                        <div class="text-right mt-30">
                          <div class="d-inline-flex">
                            <button v-if="!isObserverUser" class="btn btn-submit" type="button"
                                    @click="saveFurtherResource"> Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <h4 class="text-primary mt-35">Resources</h4>
          <div v-if="furtherResources.length" class="mt-30">
            <div class="row">
              <div v-for="(resource, _rIndex) in furtherResources"
                   :key="_rIndex"
                   class="col-md-12 col-lg-7 mr-auto"
              >
                <Resource
                  :date="resource.date"
                  :description1="resource.subtitle"
                  :description2="resource.description"
                  :download-resource="resource"
                  :icon-path="resource.icon.relative_url"
                  :title="resource.title"
                ></Resource>
              </div>
            </div>
          </div>
          <div v-else class="mt-30">
            No resource(s) available
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import MapChart from '@/components/common/MapChart'
import moment from 'moment'
import BwLoader from '@/components/ui/BwLoader'
import _ from 'lodash'
import { required } from 'vuelidate/lib/validators'
import FormMixin from '@/mixins/form-mixin'
import { validationMixin } from 'vuelidate'
import UtilityMixin from '@/mixins/utility-mixin'
import Menus from '@/config/menus'
import { ContentTypes } from '@/config/constants'
import Resource from '@/components/common/Resource'
import { ContentLoader } from 'vue-content-loader'

export default {
  name: 'ContractDetail',
  components: {
    MapChart,
    BwLoader,
    Resource,
    ContentLoader
  },
  props: {},
  data () {
    return {
      regionGroupTableHeads: [
        {
          title: 'Region',
          subtitle: ''
        },
        {
          title: 'Purchase Point',
          subtitle: ''
        },
        {
          title: 'Farmer Group/Label',
          subtitle: ''
        },
        {
          title: 'Farmer Subgroup',
          subtitle: ''
        },
        {
          title: 'Altitude (masl)',
          subtitle: ''
        },
        {
          title: 'Coffee species',
          subtitle: ''
        },
        {
          title: 'Harvest period (main season)',
          subtitle: ''
        },
        {
          title: 'Harvest period (Fly season)',
          subtitle: ''
        }
      ],
      mapCenter: {},
      mapHeight: 575,
      geoPositions: [],
      loading: false,
      contract: {},
      contractBloomShare: 0,
      billOfLadingTableRows: [],
      importerContractRefineTableRows: [],
      isSecondaryContract: true,
      regionAndFarmersGroupRows: [],
      contractRegion: '',
      contractGroup: '',
      contractSubGroup: '',
      contractRegionGroup: [],
      enabledMapLegends: {
        region: {
          name: 'Region',
          icon: '006330.svg'
        },
        farmerGroup: {
          name: 'Farmer Groups/Labels',
          icon: '69AA1A.svg'
        },
        farmerSubGroup: {
          name: 'Farmer Subgroups',
          icon: 'ED1C24.svg'
        },
        purchasePoint: {
          name: 'Purchase Point',
          icon: '636466.svg'
        },
        exportMill: {
          name: 'Export Mill',
          icon: '66A183.svg'
        },
        portOfOrigin: {
          name: 'Port of Origin',
          icon: 'A5CD8F.svg'
        },
        portOfDestination: {
          name: 'Port of Destination',
          icon: '2E4213.svg'
        }
      },
      furtherResources: [
        {
          title: '',
          subtitle: '',
          more_link: '',
          date: new Date().getFullYear() + '-' + new Date().getMonth() + '-' + new Date().getDate(),
          icon: {
            previewUrl: null,
            file: null,
            name: null,
            tsync_id: null
          },
          file: {
            file: null,
            name: null,
            tsync_id: null
          }
        }
      ],
      photoTsyncIdMapping: {},
      photoPreviewMapping: {},
      fileTsyncIdMapping: {},
      mapContentLoading: true
    }
  },
  async created () {
    const vm = this
    await this.getTraceabilityDetailPageContent()
    this.mapHeight = _.get(vm.$refs, 'contractInfo.clientHeight') || 660
  },
  mixins: [FormMixin, validationMixin, UtilityMixin],
  validations: {
    furtherResources: {
      required,
      $each: {
        title: {
          required
        },
        subtitle: {
          required
        },
        date: {
          required
        },
        icon: {
          tsync_id: {
            required
          }
        }
      }
    }
  },
  computed: {
    resourcesContentsState () {
      return this.$store.state.Traceability.traceabilityContents
    },
    furtherResourceContents () {
      const _year = this.$route.params.id ? this.$route.params.id : 0
      return this.$store.getters.getTraceabilityResourcesByContractId('contract-' + _year)
    }
  },
  methods: {
    async getContractInformation () {
      this.contract = this.$store.getters.getTraceabilityContractDetailContents
      this.isSecondaryContract = _.get(this.contract, 'is_secondary') ?? false

      if (this.isSecondaryContract) {
        this.contractRegionGroup = _.get(this.contract, 'parent.region_groups') || []
      } else {
        this.contractRegionGroup = _.get(this.contract, 'region_groups') || []
      }

      if (!_.isEmpty(this.contractRegionGroup)) {
        // region
        let regions = this.contractRegionGroup.map(function (item) {
          const region = _.get(item, 'region.name') || null
          if (region) {
            return region
          }
        })
        regions = _.filter(regions, function (o) {
          if (o) return o
        })
        this.contractRegion = regions.length ? regions : null

        // group
        let groups = this.contractRegionGroup.map(function (item) {
          const group = _.get(item, 'group.name') || null
          if (group) {
            return group
          }
        })
        groups = _.filter(groups, function (o) {
          if (o) return o
        })
        this.contractGroup = groups.length ? groups : null

        // sub group
        let subGroups = this.contractRegionGroup.map(function (item) {
          const subGroup = _.get(item, 'sub_group.name') || null
          if (subGroup) {
            return subGroup
          }
        })
        subGroups = _.filter(subGroups, function (o) {
          if (o) return o
        })
        this.contractSubGroup = subGroups.length ? subGroups : null
      } else {
        this.contractRegion = ''
        this.contractGroup = ''
        this.contractSubGroup = ''
      }

      let shipmentStartDate = _.get(this.contract, 'shipment_start_date') ?? 0
      if (shipmentStartDate) {
        shipmentStartDate = moment(shipmentStartDate).format('MMM YYYY')
      } else {
        shipmentStartDate = 'N/A'
      }

      let shipmentEndDate = _.get(this.contract, 'shipment_end_date') ?? 0
      if (shipmentEndDate) {
        shipmentEndDate = moment(shipmentEndDate).format('MMM YYYY')
      } else {
        shipmentEndDate = 'N/A'
      }

      this.contract.delivery_month = shipmentStartDate + ' - ' + shipmentEndDate
      // exporter
      const exporter = _.get(this.contract, 'exporter') || null
      this.contract.exporter_name = exporter ? exporter.name : 'N/A' // This is for preventing vuex warning
      // importer
      const importer = _.get(this.contract, 'importer') || null
      this.contract.importer_name = importer ? importer.name : 'N/A' // This is for preventing vuex warning
      // customer
      const customer = _.get(this.contract, 'customer') || null
      this.contract.customer_name = customer ? customer.name : 'N/A' // This is for preventing vuex warning
      this.contractRegion = _.uniq(this.contractRegion)
      this.contractRegion = this.contractRegion.length ? this.contractRegion.join(', ') : 'N/A'
      this.contractGroup = _.uniq(this.contractGroup)
      this.contractGroup = this.contractGroup.length ? this.contractGroup.join(', ') : 'N/A'
      this.contractSubGroup = _.uniq(this.contractSubGroup)
      this.contractSubGroup = this.contractSubGroup.length ? this.contractSubGroup.join(', ') : 'N/A'
    },
    getContractBillOfLading () {
      const billOfLadingContents = this.$store.getters.getTraceabilityAllCountryBillOfLadingContents
      for (const _data of billOfLadingContents) {
        // if bill of ladings have container(s)
        if (_data.containers.length > 0) {
          for (const container of _data.containers) {
            const _tempContainer = []
            _tempContainer.push(_.get(_data, 'bill_of_lading_number') || 'N/A')
            _tempContainer.push(_.get(container, 'container_no') || 'N/A')
            _tempContainer.push(_.get(container, 'ico_lot') || 'N/A')
            _tempContainer.push(_.get(_data, 'port_of_origin.name') || 'N/A')
            _tempContainer.push(_.get(_data, 'port_of_destination.name') || 'N/A')
            _tempContainer.push(_.get(_data, 'mill.name') || 'N/A')

            this.billOfLadingTableRows.push(_tempContainer)
          }
        } else {
          const _tempContainer = []
          _tempContainer.push(_.get(_data, 'bill_of_lading_number') || 'N/A')
          _tempContainer.push('N/A')
          _tempContainer.push('N/A')
          _tempContainer.push(_.get(_data, 'port_of_origin.name') || 'N/A')
          _tempContainer.push(_.get(_data, 'port_of_destination.name') || 'N/A')
          _tempContainer.push(_.get(_data, 'mill.name') || 'N/A')

          this.billOfLadingTableRows.push(_tempContainer)
        }
      }
    },
    getImporterContractRefine () {
      const importerContractRefines = this.$store.getters.getTraceabilityAllCountryImporterContractRefineContents
      for (const _data of importerContractRefines) {
        const tempData = []
        tempData.push(_.get(_data, 'bill_of_lading_number') || 'N/A')
        tempData.push(_.get(_data, 'container_no') || 'N/A')
        tempData.push(_.get(_data, 'ico_lot') || 'N/A')
        tempData.push(_.get(_data, 'port_of_origin.name') || 'N/A')
        tempData.push(_.get(_data, 'port_of_destination.name') || 'N/A')
        tempData.push(_.get(_data, 'mill.name') || 'N/A')
        this.importerContractRefineTableRows.push(tempData)
      }
    },
    getRegionMetas () {
      return this.$store.getters.getTraceabilityRegionMetaContents
    },
    getGroupMetas () {
      return this.$store.getters.getTraceabilityGroupMetaContents
    },
    getRegionAndFarmersGroupMapPosition () {
      this.mapContentLoading = true
      const regionFarmerGroups = this.contractRegionGroup
      for (const _data of regionFarmerGroups) {
        let _tempContainer = {}

        const regionGps = _.get(_data, 'region.location') || null
        const groupGps = _.get(_data, 'group.location') || null
        const subGroupGps = _.get(_data, 'sub_group.location') || null
        const purchasePointGps = _.get(_data, 'purchase_point.location') || null

        const regionName = _.get(_data, 'region.name') || 'N/A'
        const regionPk = _.get(_data, 'region_meta.id') || 0
        const groupName = _.get(_data, 'group.name') || 'N/A'
        const groupPk = _.get(_data, 'group_meta.id') || 0
        const subGroupName = _.get(_data, 'sub_group.name') || 'N/A'
        const subGroupPk = _.get(_data, 'sub_group_meta.id') || 0
        const purchasePointName = _.get(_data, 'purchase_point.name') || 'N/A'

        let regionLink = ''
        if (regionPk) {
          regionLink = `<a href='/farmers-region-group/detail/${regionPk}/region/'>${regionName}</a>`
        } else {
          regionLink = 'N/A'
        }

        let groupLink = ''
        if (groupPk) {
          groupLink = `<a href='/farmers-region-group/detail/${groupPk}/group/'>${groupName}</a>`
        } else {
          groupLink = 'N/A'
        }

        let subGroupLink = ''
        if (subGroupPk) {
          subGroupLink = `<a href='/farmers-region-group/detail/${subGroupPk}/sub-group/'>${subGroupName}</a>`
        } else {
          subGroupLink = 'N/A'
        }

        // Set Map Center
        if (_.isEmpty(this.mapCenter)) {
          let lat = _.get(regionGps, 'latitude') || 0
          let lng = _.get(regionGps, 'longitude') || 0
          if (!lat && !lng) {
            lat = _.get(groupGps, 'latitude') || 0
            lng = _.get(groupGps, 'longitude') || 0
          } else if (!lat && !lng) {
            lat = _.get(subGroupGps, 'latitude') || 0
            lng = _.get(subGroupGps, 'longitude') || 0
          } else if (!lat && !lng) {
            lat = _.get(purchasePointGps, 'latitude') || 0
            lng = _.get(purchasePointGps, 'longitude') || 0
          }
          if (lat || lng) {
            this.mapCenter.lat = parseFloat(lat)
            this.mapCenter.lng = parseFloat(lng)
          }
        }
        // Region GPS
        if (!_.isEmpty(regionGps)) {
          const lat = _.get(regionGps, 'latitude') || 0
          const lng = _.get(regionGps, 'longitude') || 0
          _tempContainer = {
            position: {
              lat: parseFloat(lat),
              lng: parseFloat(lng)
            },
            name: 'Region',
            description: regionLink || regionName,
            type: 'region'
          }

          this.geoPositions.push(_tempContainer)
        }
        // Group GPS
        if (!_.isEmpty(groupGps)) {
          const lat = _.get(groupGps, 'latitude') || 0
          const lng = _.get(groupGps, 'longitude') || 0
          _tempContainer = {
            position: {
              lat: parseFloat(lat),
              lng: parseFloat(lng)
            },
            name: 'Group',
            description: groupLink || groupName,
            type: 'farmerGroup'
          }

          this.geoPositions.push(_tempContainer)
        }
        // Sub Group GPS
        if (!_.isEmpty(subGroupGps)) {
          const lat = _.get(subGroupGps, 'latitude') || 0
          const lng = _.get(subGroupGps, 'longitude') || 0
          _tempContainer = {
            position: {
              lat: parseFloat(lat),
              lng: parseFloat(lng)
            },
            name: 'Subgroup',
            description: subGroupLink || subGroupName,
            type: 'farmerSubGroup'
          }

          this.geoPositions.push(_tempContainer)
        }
        // Purchase Point
        if (!_.isEmpty(purchasePointGps)) {
          const lat = _.get(purchasePointGps, 'latitude') || 0
          const lng = _.get(purchasePointGps, 'longitude') || 0
          _tempContainer = {
            position: {
              lat: parseFloat(lat),
              lng: parseFloat(lng)
            },
            name: 'Purchase Point',
            description: purchasePointName,
            type: 'purchasePoint'
          }

          this.geoPositions.push(_tempContainer)
        }
      }
      const _content = this.$store.getters.getTraceabilityAllCountryBillOfLadingContents
      for (const _data of _content) {
        let _tempContainer = {}

        const millGps = _.get(_data, 'mill.location') || null
        const portOfOriginGps = _.get(_data, 'port_of_origin.location') || null
        const portOfDestinationGps = _.get(_data, 'port_of_destination.location') || null

        const millName = _.get(_data, 'mill.name') || null
        const portOfOriginName = _.get(_data, 'port_of_origin.name') || null
        const portOfDestinationName = _.get(_data, 'port_of_destination.name') || null

        // Mill GPS
        if (!_.isEmpty(millGps)) {
          const lat = millGps.latitude || 0
          const lng = millGps.longitude || 0
          _tempContainer = {
            position: {
              lat: parseFloat(lat),
              lng: parseFloat(lng)
            },
            name: 'Mill',
            description: millName,
            type: 'exportMill'
          }

          this.geoPositions.push(_tempContainer)
        }
        // Port of Origin GPS
        if (!_.isEmpty(portOfOriginGps)) {
          const lat = portOfOriginGps.latitude || 0
          const lng = portOfOriginGps.longitude || 0
          _tempContainer = {
            position: {
              lat: parseFloat(lat),
              lng: parseFloat(lng)
            },
            name: 'Port of Origin',
            description: portOfOriginName,
            type: 'portOfOrigin'
          }

          this.geoPositions.push(_tempContainer)
        }
        // Port Of DestinationGps GPS
        if (!_.isEmpty(portOfDestinationGps)) {
          const lat = portOfDestinationGps.latitude || 0
          const lng = portOfDestinationGps.longitude || 0
          _tempContainer = {
            position: {
              lat: parseFloat(lat),
              lng: parseFloat(lng)
            },
            name: 'Port of Destination',
            description: portOfDestinationName,
            type: 'portOfDestination'
          }

          this.geoPositions.push(_tempContainer)
        }

        // Set Map Center
        if (_.isEmpty(this.mapCenter)) {
          let lat = _.get(millGps, 'latitude') || 0
          let lng = _.get(millGps, 'longitude') || 0
          if (!lat && !lng) {
            lat = _.get(portOfOriginGps, 'latitude') || 0
            lng = _.get(portOfOriginGps, 'longitude') || 0
          } else if (!lat && !lng) {
            lat = _.get(portOfDestinationGps, 'latitude') || 0
            lng = _.get(portOfDestinationGps, 'longitude') || 0
          }
          if (lat || lng) {
            this.mapCenter.lat = parseFloat(lat)
            this.mapCenter.lng = parseFloat(lng)
          }
        }
      }
      if (_.isEmpty(this.mapCenter)) {
        this.mapCenter.lat = 14.769611
        this.mapCenter.lng = 32.508846
      }
      this.mapContentLoading = false
    },
    getRegionAndFarmersGroupTable () {
      this.isSecondaryContract = _.get(this.contract, 'is_secondary') ?? false
      let subGroups = []
      if (this.isSecondaryContract) {
        subGroups = _.get(this.contract, 'parent.region_groups') || []
      } else {
        subGroups = _.get(this.contract, 'region_groups') || []
      }
      this.contractBloomShare = _.get(this.contract, 'bloom_share') || 0
      if (this.contractBloomShare <= 0) {
        this.contractBloomShare = _.get(this.contract, 'parent.bloom_share') || 0
      }

      for (const _data of subGroups) {
        try {
          const _tempContainer = []
          const regionName = _.get(_data, 'region.name') || 'N/A'
          const regionMeta = _.get(_data, 'region_meta') || null
          let regionPk = _.get(_data, 'region_meta.id') || 0
          const groupName = _.get(_data, 'group.name') || 'N/A'
          const groupMeta = _.get(_data, 'group_meta') || 0
          let groupPk = _.get(_data, 'group_meta.id') || 0
          const subGroupMeta = _.get(_data, 'sub_group_meta') || 0
          let subGroupPk = _.get(_data, 'sub_group_meta.id') || 0
          const subGroupName = _.get(_data, 'sub_group.name') || 'N/A'

          if (regionMeta && _.get(regionMeta, 'is_deleted')) {
            regionPk = 0
          }
          if (groupMeta && _.get(groupMeta, 'is_deleted')) {
            groupPk = 0
          }
          if (subGroupMeta && _.get(subGroupMeta, 'is_deleted')) {
            subGroupPk = 0
          }

          let regionLink = ''
          if (regionPk) {
            regionLink = `<a href='/farmers-region-group/detail/${regionPk}/region/'>${regionName}</a>`
          } else {
            regionLink = 'N/A'
          }
          console.log(regionLink)

          let groupLink = ''
          if (groupPk) {
            groupLink = `<a href='/farmers-region-group/detail/${groupPk}/group/'>${groupName}</a>`
          } else {
            groupLink = 'N/A'
          }

          let subGroupLink = ''
          if (subGroupPk) {
            subGroupLink = `<a href='/farmers-region-group/detail/${subGroupPk}/sub-group/'>${subGroupName}</a>`
          } else {
            subGroupLink = 'N/A'
          }

          _tempContainer.push(regionLink)
          const purchasePointName = _.get(_data, 'purchase_point.name') || 'N/A'
          _tempContainer.push(purchasePointName)
          _tempContainer.push(groupLink)
          _tempContainer.push(subGroupLink)
          // const deliveryShare = _.get(_data, 'delivery_share') || 'N/A'
          // _tempContainer.push(deliveryShare)

          if (!_.isEmpty(regionMeta)) {
            let altitudeMax = _.get(regionMeta, 'altitude_max') || 0
            if (typeof altitudeMax === 'number') {
              altitudeMax = altitudeMax.toLocaleString()
            } else {
              altitudeMax = Number(altitudeMax).toLocaleString()
            }

            let altitudeMin = _.get(regionMeta, 'altitude_min') || 0
            if (typeof altitudeMin === 'number') {
              altitudeMin = altitudeMin.toLocaleString()
            } else {
              altitudeMin = Number(altitudeMin).toLocaleString()
            }

            // Altitude
            const altitude = altitudeMin + ' - ' + altitudeMax
            _tempContainer.push(altitude)
            // Coffee Species
            let coffeeSpecies = _.get(regionMeta, 'coffee_species') || null
            coffeeSpecies = coffeeSpecies ? coffeeSpecies.map(value => value.name).join(', ') : null
            coffeeSpecies = coffeeSpecies || 'N/A'
            _tempContainer.push(coffeeSpecies)

            let mainStart = ''
            let mainEnd = ''
            if (_.get(regionMeta, 'main_start_date')) {
              mainStart = moment(regionMeta.main_start_date).format('DD MMM')
            }
            if (_.get(regionMeta, 'main_end_date')) {
              mainEnd = moment(regionMeta.main_end_date).format('DD MMM')
            }
            let flyStart = ''
            let flyEnd = ''
            if (_.get(regionMeta, 'fly_start_date')) {
              flyStart = moment(regionMeta.fly_start_date).format('DD MMM')
            }
            if (_.get(regionMeta, 'fly_end_date')) {
              flyEnd = moment(regionMeta.fly_end_date).format('DD MMM')
            }

            if (mainStart || mainEnd) {
              _tempContainer.push((mainStart || 'N/A') + ' - ' + (mainEnd || 'N/A'))
            } else {
              _tempContainer.push('N/A')
            }
            if (flyStart || flyEnd) {
              _tempContainer.push((flyStart || 'N/A') + ' - ' + (flyEnd || 'N/A'))
            } else {
              _tempContainer.push('N/A')
            }
          } else {
            _tempContainer.push('N/A')
            _tempContainer.push('N/A')
            _tempContainer.push('N/A')
            _tempContainer.push('N/A')
          }

          this.regionAndFarmersGroupRows.push(_tempContainer)
        } catch (e) {
          this.errorLog(e)
        }
      }
    },
    async getTraceabilityDetailPageContent () {
      this.loading = true
      await this.$store.dispatch('getTraceabilityContentsFromServer')
      await this.$store.dispatch('getTraceabilityContractDetailFromServer', { id: this.$route.params.id })
      await this.getContractInformation()
      this.isSecondaryContract = _.get(this.contract, 'is_secondary') ?? false
      let contract = null
      if (this.isSecondaryContract) {
        contract = _.get(this.contract, 'parent') || []
        await this.$store.dispatch('getTraceabilityBillOfLadingFromServer', { contract: contract.id })
      } else {
        await this.$store.dispatch('getTraceabilityBillOfLadingFromServer', { contract: this.$route.params.id })
      }
      await this.$store.dispatch('getTraceabilityImporterContractRefineFromServer', { contract: this.$route.params.id })
      this.getContractBillOfLading()
      this.getImporterContractRefine()
      this.getRegionAndFarmersGroupTable()
      this.getRegionAndFarmersGroupMapPosition()
      this.loading = false
    },
    // Further Resources
    addMoreFurtherResource () {
      this.furtherResources.push({
        title: '',
        subtitle: '',
        more_link: '',
        date: new Date().getFullYear() + '-' + new Date().getMonth() + '-' + new Date().getDate(),
        icon: {
          previewUrl: null,
          file: null,
          name: null,
          tsync_id: null
        },
        file: {
          file: null,
          name: null,
          tsync_id: null,
          size: 0
        }
      })
    },
    onIconChange (e, index) {
      // index => key statistics array objects index
      // if (!this.furtherResources[index].icon) {
      //   this.furtherResources[index].icon = {}
      // }
      if (e.target.files.length > 0) {
        const file = e.target.files[0]
        this.furtherResources[index].icon.previewUrl = URL.createObjectURL(file)
        this.furtherResources[index].icon.file = file
        this.furtherResources[index].icon.name = file.name
        this.furtherResources[index].icon.tsync_id = this.uuidV4()
      } else {
        // if icon upload widget clicked and no image selected
        this.furtherResources[index].icon.previewUrl = null
        this.furtherResources[index].icon.file = null
        this.furtherResources[index].icon.name = null
        this.furtherResources[index].icon.tsync_id = null
      }
    },
    onFileChange (e, index) {
      // if (!this.furtherResources[index].file) {
      //   this.furtherResources[index].file = {}
      // }
      if (e.target.files.length > 0) {
        const file = e.target.files[0]
        this.furtherResources[index].file.file = file
        this.furtherResources[index].file.name = file.name
        this.furtherResources[index].file.tsync_id = this.uuidV4()
        this.furtherResources[index].file.size = file.size
      } else {
        this.furtherResources[index].file.file = null
        this.furtherResources[index].file.name = null
        this.furtherResources[index].file.tsync_id = null
        this.furtherResources[index].file.size = 0
      }
    },
    async saveFurtherResource () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.$notify({
          title: '',
          text: 'Please fix error(s) in form.',
          type: 'error',
          duration: 5000
        })
      } else {
        const vm = this
        this.photoTsyncIdMapping = {}
        this.photoPreviewMapping = {}
        this.fileTsyncIdMapping = {}

        const keyStatsPromises = []
        const promises = await vm.furtherResources.reduce(async (memo, v) => {
          await memo
          keyStatsPromises.push(await vm.submitEachFurtherResource(v))
        }, [])
        _.unset(promises, '')
        Promise.all(keyStatsPromises).then((values) => {
          const photoPromises = []
          const filePromises = []
          values.map((item) => {
            const iconId = item.data.icon ? item.data.icon.id : 0
            const iconTsyncId = item.data.icon ? item.data.icon.tsync_id : ''
            if (iconId === 0 || iconTsyncId === '') return
            const photoFile = vm.photoTsyncIdMapping[iconTsyncId]
            if (_.get(photoFile, 'name')) {
              const response = vm.uploadMultipartPhoto(iconId, photoFile)
              photoPromises.push(response)
            }

            // file upload
            const fileId = item.data.file ? item.data.file.id : 0
            const fileTsyncId = item.data.file ? item.data.file.tsync_id : ''
            if (fileId === 0 || fileTsyncId === '') return
            const file = this.fileTsyncIdMapping[fileTsyncId]
            if (_.get(file, 'file.name')) {
              const fileResponse = this.uploadFile(fileId, file.file)
              filePromises.push(fileResponse)
            }
          })
          Promise.all(photoPromises).then((iconResponses) => {
            this.$notify({
              title: 'Further Resources',
              text: 'Further Resource(s) updated successfully.',
              type: 'success', // 'warn', 'error', 'success'
              duration: 5000
            })
            // this.$forceUpdate()
          })
          Promise.all(filePromises).then((iconResponses) => {
            // this.$forceUpdate()
          })
        })
      }
    },
    async submitEachFurtherResource (data) {
      const vm = this
      const formData = _.cloneDeep(data)
      const iconTsyncId = (formData.icon && formData.icon.tsync_id) ? formData.icon.tsync_id : vm.uuidV4()
      if (_.get(data, 'icon.file')) {
        vm.photoTsyncIdMapping[iconTsyncId] = data.icon.file
      }
      if (data.icon && data.icon.previewUrl) {
        vm.photoPreviewMapping[iconTsyncId] = data.icon.previewUrl
      }
      if (!formData.icon.id && formData.icon.name) {
        formData.icon = {
          tsync_id: iconTsyncId,
          extension: formData.icon.name.split('.').pop()
        }
      } else {
        try {
          formData.icon.extension = formData.icon.name.split('.').pop()
        } catch (e) {

        }
      }

      // File handling
      let fileSize = 0
      const fileTsyncId = (formData.file && formData.file.tsync_id) ? formData.file.tsync_id : vm.uuidV4()
      if (_.get(data, 'file.file')) {
        vm.fileTsyncIdMapping[fileTsyncId] = data.file
      }
      fileSize = _.get(data, 'file.file') ? _.get(data, 'file.file.size') : 0// uploaded file size in KB
      if (!formData.file.id && formData.file.name) {
        formData.file = {
          tsync_id: fileTsyncId,
          extension: formData.file.name.split('.').pop()
        }
      }
      if (!_.get(formData, 'file.tsync_id')) {
        delete formData.file
        formData.file = null
      }

      formData.country = _.get(vm, 'contract.country_id') || null
      formData.date = Date.parse(formData.date)
      formData.description = fileSize // description store file size in KB
      formData.content = this.$store.getters.getContentByAppModule(Menus.traceability.key).id
      formData.year = 'contract-' + this.$route.params.id // this used for identify which contract resource is this
      formData.content_type = ContentTypes.RESOURCE_MINI_CARD
      formData.parent = this.$store.getters.getParentContentItemByContentType(
        ContentTypes.RESOURCE, Menus.traceability.key).id
      return await this.$store.dispatch('createOrUpdateTraceabilityContents', formData)
    }
  },
  watch: {
    furtherResourceContents: {
      deep: true,
      handler (newValue) {
        if (newValue.length) {
          const data = []
          _.cloneDeep(newValue).map((item) => {
            if (!_.get(item, 'file.name')) {
              item.file = {
                file: null,
                name: null,
                tsync_id: null
              }
            }
            if (!_.get(item, 'icon.name')) {
              item.icon = {
                previewUrl: null,
                file: null,
                name: null,
                tsync_id: null
              }
            }
            const _date = item.date
            data.push({
              ...item,
              date: moment(_date).format('yyyy-MM-DD')
            })
          })
          this.furtherResources = data
        } else {
          this.furtherResources = []
        }
      }
    }
  }
}

</script>

<style lang="scss" scoped>
.clear-file {
  cursor: pointer;
}

.icon-preview {
  height: 42px;
}

.clear-image {
  cursor: pointer;
}

.drop-sm {
  padding-right: 60px !important;
  max-width: 52%; // if file name is long than it wont overflow next close button
}
</style>
